var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-header',{attrs:{"header-class":"pt-0"}},[_c('div',{staticClass:"flex justify-content-end align-content-center gap-x-2"},[_c('h4',[_vm._v("تفاصيل المرحلة")]),_c('unicon',{staticStyle:{"margin-top":"-10px"},attrs:{"name":"university","fill":"#2EC4B6"}})],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('ek-input-text',{attrs:{"label":" اسم الصف ","name":"name","placeholder":"ادخل اسم الصف"},model:{value:(_vm.universityDetailsList.name),callback:function ($$v) {_vm.$set(_vm.universityDetailsList, "name", $$v)},expression:"universityDetailsList.name"}}),_c('ek-input-text',{attrs:{"name":"studentsCount","label":" عدد المواد ","placeholder":"عدد المواد"},model:{value:(_vm.universityDetailsList.studentsCount),callback:function ($$v) {_vm.$set(_vm.universityDetailsList, "studentsCount", $$v)},expression:"universityDetailsList.studentsCount"}}),_c('ek-input-text',{attrs:{"name":"date","label":" تاريخ الاضافة ","placeholder":"تاريخ الاضافة","value":new Date(
                                _vm.universityDetailsList.dateCreated
                            ).toLocaleDateString()}})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('ek-input-text',{attrs:{"name":"facultiesCount","label":" اسم المرحلة ","placeholder":"ادخل اسم المرحلة"},model:{value:(_vm.universityDetailsList.facultiesCount),callback:function ($$v) {_vm.$set(_vm.universityDetailsList, "facultiesCount", $$v)},expression:"universityDetailsList.facultiesCount"}}),_c('ek-input-text',{attrs:{"name":"studentsCount","label":" عدد الطلاب المسجلين ","placeholder":"عدد الطلاب المسجلين"},model:{value:(_vm.universityDetailsList.studentsCount),callback:function ($$v) {_vm.$set(_vm.universityDetailsList, "studentsCount", $$v)},expression:"universityDetailsList.studentsCount"}})],1)],1)],1)],1),_c('b-card',[_c('b-card-header',{attrs:{"header-class":"pt-0"}},[_c('div',{staticClass:"flex justify-content-end align-content-center"},[_c('h4',[_vm._v("الصفوف")]),_c('svg',{staticStyle:{"margin-top":"-10px"},attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"#2EC4B6","d":"M12 3L1 9l11 6l9-4.91V17h2V9M5 13.18v4L12 21l7-3.82v-4L12 17z"}})])])]),_c('b-card-body',[_c('ek-table',{attrs:{"columns":_vm.classesColumn,"items":_vm.universityDetailsList.faculties,"no_select":true,"no_delete":true},on:{"details":_vm.detailsAppPanel},scopedSlots:_vm._u([{key:"items.dateCreated",fn:function(ref){
                            var value = ref.value;
return [_c('span',[_vm._v(" "+_vm._s(new Date(value).toLocaleDateString()))])]}},{key:"items.type",fn:function(ref){
                            var value = ref.value;
return [(value == 1)?_c('span',[_vm._v("مرحلة التعليم المدرسي ")]):_vm._e()]}},{key:"items.id",fn:function(ref){
                            var value = ref.value;
return (_vm.tab == 1)?[_c('b-button',{staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"2px 6px !important"},attrs:{"size":"sm","variant":"flat-secondary"},on:{"click":function($event){return _vm.goToDetails(value)}}},[_c('unicon',{attrs:{"name":"ellipsis-v","width":"18"}})],1)]:undefined}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }