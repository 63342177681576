<template>
    <div>
        <b-card>
            <b-card-header header-class="pt-0">
                <div
                    class="flex justify-content-end align-content-center gap-x-2"
                >
                    <h4>تفاصيل المرحلة</h4>
                    <unicon
                        name="university"
                        fill="#2EC4B6"
                        style="margin-top: -10px"
                    ></unicon>
                </div>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="6" lg="6">
                        <ek-input-text
                            label=" اسم الصف "
                            name="name"
                            placeholder="ادخل اسم الصف"
                            v-model="universityDetailsList.name"
                        >
                        </ek-input-text>

                        <ek-input-text
                            name="studentsCount"
                            label=" عدد المواد "
                            placeholder="عدد المواد"
                            v-model="universityDetailsList.studentsCount"
                        >
                        </ek-input-text>
                        <ek-input-text
                            name="date"
                            label=" تاريخ الاضافة "
                            placeholder="تاريخ الاضافة"
                            :value="
                                new Date(
                                    universityDetailsList.dateCreated
                                ).toLocaleDateString()
                            "
                        >
                        </ek-input-text>
                    </b-col>

                    <b-col cols="12" md="6" lg="6">
                        <ek-input-text
                            name="facultiesCount"
                            label=" اسم المرحلة "
                            placeholder="ادخل اسم المرحلة"
                            v-model="universityDetailsList.facultiesCount"
                        >
                        </ek-input-text>

                        <ek-input-text
                            name="studentsCount"
                            label=" عدد الطلاب المسجلين "
                            placeholder="عدد الطلاب المسجلين"
                            v-model="universityDetailsList.studentsCount"
                        >
                        </ek-input-text>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card>
            <b-card-header header-class="pt-0">
                <div class="flex justify-content-end align-content-center">
                    <h4>الصفوف</h4>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        style="margin-top: -10px"
                    >
                        <path
                            fill="#2EC4B6"
                            d="M12 3L1 9l11 6l9-4.91V17h2V9M5 13.18v4L12 21l7-3.82v-4L12 17z"
                        />
                    </svg>
                </div>
            </b-card-header>
            <b-card-body>
                <ek-table
                    :columns="classesColumn"
                    :items="universityDetailsList.faculties"
                    @details="detailsAppPanel"
                    :no_select="true"
                    :no_delete="true"
                >
                    <template slot="items.dateCreated" slot-scope="{ value }">
                        <span> {{ new Date(value).toLocaleDateString() }}</span>
                    </template>
                    <template slot="items.type" slot-scope="{ value }">
                        <span v-if="value == 1">مرحلة التعليم المدرسي </span>
                    </template>
                    <template
                        v-if="tab == 1"
                        slot="items.id"
                        slot-scope="{ value }"
                    >
                        <b-button
                            size="sm"
                            variant="flat-secondary"
                            class="btn-icon rounded-circle"
                            style="padding: 2px 6px !important"
                            @click="goToDetails(value)"
                        >
                            <unicon name="ellipsis-v" width="18"></unicon>
                        </b-button>
                    </template>
                </ek-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            tab: 0,
        };
    },
    components: {},
    computed: {
        ...mapState({
            classesColumn: (state) => state.university.classesColumn,

            universityDetailsList: (state) =>
                state.university.universityDetailsList,
        }),
        ...mapGetters(["universities"]),
    },
    methods: {
        ...mapActions(["getGetUnRead", "getUniversityDetails"]),
        detailsAppPanel({ row }) {
            this.$store.commit("IS_DIALOG_OPEN", true);
            this.$store.commit("SET_UNIVERSITY_DTO", row);
        },
        deleteUni(e) {
            this.$store.dispatch("deleteUniversities", e);
        },
        goToDetails(id) {
            console.log(id);
        },
    },
    created() {
        this.getUniversityDetails(this.$route.params.id);
    },
};
</script>
